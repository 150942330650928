import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";

import logo from "./../../assets/images/logo.png";
import bg from "./../../assets/images/bg.png";
import workfromhome from "./../../assets/images/workfromhome.png";
import male1 from "../../assets/images/male1.png";

import tick from "./../../assets/images/tick_red.png";
import { APP_COLOURS } from "../APP_VARS";
import { api_login } from "../Api";
import * as animationData from "../../assets/lottie/lf30_editor_t3bgbljb.json";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			emailInput: "",
			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	loginButton = async () => {
		this.setState({
			loading: true,
		});

		await sessionStorage.setItem("email", this.state.emailInput);
		// this.props.history.push("/dashboard/");
		// setTimeout(() => this.checkRSVP(), 3000);
		this.loginFunc();
	};

	loginFunc = async () => {
		let url = "https://www.tetrice.co.za/REST/accountsapi/post_login.php";
		let password = this.state.password;
		let email = this.state.emailInput;

		var raw = {
			password: password,
			email: email,
		};

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);
							let jwt = await sessionStorage.setItem(
								"jwt",
								JSON.stringify(responseData.jwt),
							);

							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify({
									id: 2,
									first_name: "Tyron",
									last_name: "Brett",
									avatar: male1,
								}),
							);

							let permission = await sessionStorage.setItem(
								"permission",
								JSON.stringify(responseData.permission),
							);

							let obj = {
								jwt: jwt,
								user_data: {
									id: 2,
									first_name: "Wesley",
									last_name: "Cheia",
									avatar: male1,
								},
								permission: "Admin",
							};

							this.props.jwt(obj);
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								checkingRVP: false,
								password: "",
								emailInput: "",
								emailValid: false,
							});
						});
				} else {
					alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						emailInput: "",
						emailValid: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					emailInput: "",
					emailValid: false,
				});
			});
	};

	componentDidMount() {
		console.log("componentDidMount");
		let auth_token = sessionStorage.getItem("auth_token");

		console.log("Approved User", auth_token);
		if (auth_token !== null) {
			this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		if (false) {
			return (
				<div
					style={{
						flex: 1,

						backgroundColor: APP_COLOURS.WHITE,
						width: screenWidth,
						height: screenHeight,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							position: "absolute",
						}}>
						<Lottie
							options={defaultOptions}
							height={screenHeight}
							width={screenWidth}
						/>
					</View>
					<View
						style={{
							flex: 1,
							height: screenHeight,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
							}}
						/>
						<Text>Checking RSVP...</Text>
					</View>
				</div>
			);
		} else if (false) {
			return (
				<div
					style={{
						flex: 1,

						backgroundColor: APP_COLOURS.WHITE,
						width: screenWidth,
						height: screenHeight,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							position: "absolute",
						}}>
						<Lottie
							options={defaultOptions}
							height={screenHeight}
							width={screenWidth}
						/>
					</View>
					<View
						style={{
							flex: 1,
							height: screenHeight,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
							}}
						/>
						<Text
							style={{
								color: "red",
							}}>
							We've recieved your RSVP as 'Not Attending'
						</Text>
						<Text
							style={{
								color: "red",
							}}>
							If this is an error, please call Deidre/Clinton or
							email deidrecheia@gmail.com
						</Text>
					</View>
				</div>
			);
		} else
			return (
				<div
					style={{
						backgroundColor: APP_COLOURS.BACKGROUND,
						flex: 1,
					}}>
					{/* <div
						style={{
							height: "100%",
							width: "100%",
							position: "fixed",
							bottom: 0,
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<Image
							source={bg}
							style={{
								width: "100%",
								height: "100%",
								resizeMode: "cover",
							}}
						/>
					</div> */}

					{/* <div
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							right: 0,
							bottom: 0,
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<Image
							source={workfromhome}
							style={{
								width: "20%",
								height: "50%",
								resizeMode: "contain",
								position: "absolute",
								right: 0,
								bottom: 0,
							}}
						/>
					</div> */}

					<div
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							right: 0,
							bottom: 0,
							// backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<Image
							source={workfromhome}
							style={{
								width: "20%",
								height: "50%",
								resizeMode: "contain",
								position: "absolute",
								right: 0,
								bottom: 0,
							}}
						/>
					</div>

					<View
						style={{
							flex: 1,
							alignItems: "center",
							// marginTop:
							// height: this.state.screenHeight,
							padding: 10,
							justifyContent: "center",
						}}>
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								alignItems: "center",
								padding: 10,
								borderRadius: 15,
								backgroundColor: APP_COLOURS.BG2,
								borderWidth: 0.3,
								borderColor: "#C5C5C5",
								shadowColor: "#ECF4FF",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,

								elevation: 16,
							}}>
							<Image
								source={logo}
								style={{
									width: 150,
									height: 100,
									resizeMode: "contain",
								}}
							/>

							{this.state.errorLogin ? (
								<View
									style={{
										width: 300,
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "red",
										}}>
										We are unable to verify your profile,
										please check your email again to confirm
										this is correct.
									</Text>
								</View>
							) : null}

							<View
								style={{
									flex: 1,
									width: "100%",
									alignItems: "center",
									// justifyContent: 'center',
								}}>
								<Fade>
									<div
										style={{
											height: "100%",
											width: "100%",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												width: "100%",
												height: 52,
												marginRight: "auto",
												marginLeft: "auto",
												alignItems: "center",
												maxWidth: 350,
												marginTop: 20,
											}}>
											<TextInput
												style={{
													width: "100%",
													height: 52,
													paddingLeft: 40,
													marginBottom: 5,
													marginTop: 0,
													fontSize: 16,
													borderRadius: 20,
													// backgroundColor:
													// 	APP_COLOURS.WHITE,
													textAlign: "left",
													color: "#FFF",
													fontWeight: "200",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor:
														APP_COLOURS.BLUE,
												}}
												placeholderTextColor={"#FFF"}
												autoCapitalize='none'
												clearButtonMode='while-editing'
												autoCompleteType='username'
												autoCorrect={false}
												autoFocus={true}
												keyboardType='email-address'
												textContentType='emailAddress'
												placeholder={`Email address`}
												value={this.state.emailInput}
												onChangeText={(text) => {
													let validated =
														this.validateEmail(
															text,
														);
													this.setState({
														emailInput: text,
													});
												}}
											/>
											{/* LINE UNDER INPUT */}
											{/* <View
												style={{
													position: 'absolute',
													width: '90%',
													bottom: 10,
													borderBottomColor: this
														.state.emailValid
														? APP_COLOURS.GREEN
														: APP_COLOURS.PLACEHOLDER,
													borderBottomWidth: 0.5,
												}}
											/> */}
											{this.state.emailValid ? (
												<Image
													source={tick}
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														position: "absolute",
														left: 15,
														top: 16,
													}}
												/>
											) : null}
										</View>
									</div>
								</Fade>

								{/* {this.state.emailValid ? ( */}
								<Fade>
									<div
										style={{
											height: "100%",
											width: "100%",
											alignItems: "center",
											justifyContent: "center",
											// display: this.state.emailValid
											// 	? 'flex'
											// 	: 'none',
										}}>
										<View
											style={{
												width: "100%",
												height: 52,
												marginRight: "auto",
												marginLeft: "auto",
												alignItems: "center",
												maxWidth: 350,
												marginTop: 20,
											}}>
											<TextInput
												style={{
													width: "100%",
													height: 52,
													paddingLeft: 40,
													marginBottom: 5,
													marginTop: 0,
													fontSize: 16,
													borderRadius: 20,
													textAlign: "left",
													color: "#FFF",
													fontWeight: "200",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor:
														APP_COLOURS.BLUE,
												}}
												placeholderTextColor={"#FFF"}
												autoCapitalize='none'
												clearButtonMode='while-editing'
												autoCompleteType='password'
												secureTextEntry={true}
												textContentType='password'
												autoCorrect={false}
												placeholder={`Password`}
												value={this.state.password}
												// defaultValue={
												// 	this.state.password
												// }
												onChangeText={(text) => {
													this.setState({
														password: text,
													});
												}}
												onSubmitEditing={() =>
													this.loginButton()
												}
											/>
											{/* LINE UNDER INPUT */}
											{/* <View
													style={{
														position: 'absolute',
														width: '90%',
														bottom: 10,
														borderBottomColor:
															this.state
																.weddingCodeInput !==
															this.state
																.weddingCode
																? APP_COLOURS.PLACEHOLDER
																: APP_COLOURS.GREEN,
														borderBottomWidth: 0.5,
													}}
												/> */}

											{this.state.password.length <
											5 ? null : (
												<Image
													source={tick}
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														position: "absolute",
														left: 15,
														top: 16,
													}}
												/>
											)}
										</View>
									</div>
								</Fade>
								{/* ) : null} */}
							</View>

							<View
								style={{
									width: "100%",
									height: 60,
									// alignItems: "center",
									maxWidth: 350,
									marginTop: 30,
								}}>
								{this.state.emailValid &&
								this.state.password !== "" ? (
									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
											}}>
											<TouchableOpacity
												onPress={() =>
													this.loginButton()
												}>
												<View
													style={{
														backgroundColor:
															APP_COLOURS.BLUE,
														flex: 1,
														padding: 13,
														justifyContent:
															"center",
														alignItems: "center",
														borderRadius: 10,
													}}>
													<Text
														style={{
															color: APP_COLOURS.WHITE,
														}}>
														Login
													</Text>
												</View>
											</TouchableOpacity>
										</div>
									</Fade>
								) : null}
							</View>
						</View>
					</View>

					{/* <View
					style={{
						width: "100%",
						height: 60,
						flexDirection: "row",
						justifyContent: "space-evenly",
					}}>
					<Text
						style={{
							color: PRIMARY,
						}}
						accessibilityRole='link'
						href={`/alternate`}>
						A universal link
					</Text>

					{this.state.pages.map((d, i) => (
						<View
							key={i}
							style={{
								flex: 1,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState(
										{
											selectedPage:
												d,
										},
									)
								}>
								<View
									style={{
										width: "100%",
										height: 70,
										alignItems: "center",
										paddingTop: 15,
										backgroundColor:
											PRIMARY,
									}}>
									<Text
										style={{
											color: OFFWHITE, // OFFWHITE,
											fontWeight:
												this
													.state
													.selectedPage ==
												d
													? "900"
													: "400",
											fontSize: 13,
										}}>
										{
											d
										}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}
				</View> */}
				</div>
			);
	}
}

export default index;
