import React, { Component } from "react";
// import { Navigate } from "react-router-dom";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ScrollView,
} from "react-native";
// import Lottie from "react-lottie";
import moment from "moment";
// import Fade from "react-reveal/Fade";
// import Font from "react-font";

import { APP_COLOURS } from "../APP_VARS";
import * as animationData from "../../assets/lottie/lf30_editor_t3bgbljb.json";
import * as animationData2 from "../../assets/lottie/lf20_q8buwK.json";

import PDFUploader from "./PDFUploader/PDFUploader";

// import Backups from "./Backups";

import logo from "./../../assets/images/camelot.png";
import male1 from "./../../assets/images/male1.png";
import close from "./../../assets/images/close.png";
// import settings from "./../../assets/images/settings.png";
// import debbie from "./../../assets/images/cloud.png";
import add from "./../../assets/images/add_btn.png";
import open from "./../../assets/images/open.png";
import tick from "./../../assets/images/tick.svg";
import flagged from "./../../assets/images/flagged.svg";
import bottomImage from "./../../assets/images/bottomImage.svg";
import backSvg from "./../../assets/images/back.svg";

import QuestionField from "./QuestionField";

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validated: true,
			screenWidth: this.props.screenWidth,
			screenHeight: this.props.screenHeight,
			loading: true,
			layoutArray: [],
			questions: this.props.report.questions,
			newPDFData: "",
			invoiceNumber: "",
		};
	}

	async componentDidMount() {
		// await this.validateSession();
	}

	// validateSession = async () => {
	// 	setTimeout(() => {
	// 		this.setState({
	// 			loading: false,
	// 			authenticating: false,
	// 		});
	// 	}, 1000);
	// 	return;

	// 	let user_data = await sessionStorage.getItem("user_data");
	// 	let email = await sessionStorage.getItem("email");
	// 	let auth_token = await sessionStorage.getItem("auth_token");
	// 	let refresh_token = await sessionStorage.getItem("refresh_token");

	// 	await this.setState({
	// 		user_data: JSON.parse(user_data),
	// 		email,
	// 		auth_token,
	// 		refresh_token,
	// 	});

	// 	if (auth_token !== null && auth_token !== "") {
	// 		setTimeout(() => {
	// 			this.setState({
	// 				loading: false,
	// 				authenticating: false,
	// 			});
	// 		}, 1000);
	// 	} else {
	// 		// this.props.history.push("/");
	// 		sessionStorage.clear();
	// 	}
	// };

	componentDidUpdate() {}

	logOut = async () => {
		await sessionStorage.clear();
		await this.props.history.push("/");
	};

	showAddItemsFormFunc = (event) => {
		event.preventDefault();
		const layout = event.nativeEvent;
		let x = layout.clientX;
		let y = layout.clientY;
		this.setState({
			showAddItemsForm: true,
			xPos: x,
			yPos: y,
			showProfileModal: true,
		});

		console.log(layout);
	};

	scrollToThisPosition = (index) => {
		console.log(index);
		// return;
		this.setState({
			layoutArraySelectedIndex: index,
		});
		let position = this.state.layoutArray.findIndex((a) => a.id === index);

		let y = this.state.layoutArray[position].layout.y;
		// console.log(y);

		this.scrollRef.scrollTo({
			x: 0, // horizontal
			y: y, // vertical
			animated: true,
		});
	};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		const defaultOptions2 = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let completed = 0;
		let count = this.props.report.questions.length;

		let completed_ = this.props.report.questions.filter(
			(dd) => dd.answer !== "",
		);
		completed = completed_.length;

		if (!this.state.validated) {
			// return <Navigate to={"/dashboard/"} />;
			return null;
		} else if (this.state.authenticating) {
			return (
				<View
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.PRIMARY,
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 200,
								height: 100,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
			);
		} else
			return (
				<div
					style={{
						// overflow: "-moz-hidden-unscrollable",
						overflow: "hidden",
						flex: 1,
						backgroundColor: APP_COLOURS.OFFWHITE,
						// backgroundColor: "red",
						height: this.props.screenHeight,
						width: this.props.screenWidth,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: this.props.screenWidth,
							flexDirection: "row",
							alignItems: "center",
							backgroundColor: "#FFF",
							padding: 10,
							borderBottomWidth: 0.3,
							borderBottomColor: "#C5C5C5",
							justifyContent: "space-between",
							height: 50,
							// paddingTop: 15,
						}}>
						<Text
							style={{
								color: "#2A4E95",
								fontSize: 16,
								fontWeight: "700",
								marginLeft: 14,
							}}>
							{this.props.report.title}
						</Text>
						<Text
							style={{
								color: "#2A4E95",
								fontSize: 10,
								fontWeight: "300",
								marginLeft: 14,
								fontStyle: "italic",
							}}>
							{completed} of {count}
						</Text>

						<Image
							source={logo}
							style={{
								width: 100,
								height: 25,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							width: this.props.screenWidth,
							height: this.props.screenHeight - 50,
							maxWidth: 400,
							marginLeft: "auto",
							marginRight: "auto",
							paddingTop: 20,
						}}>
						<ScrollView
							ref={(ref) => {
								this.scrollRef = ref;
							}}
							scrollEventThrottle={16}>
							{this.props.report.questions.map((d, i) => {
								return (
									<View
										onLayout={(e) =>
											this.setState({
												layoutArray: [
													...this.state.layoutArray,
													{
														id: i,
														layout: e.nativeEvent
															.layout,
													},
												],
											})
										}
										style={{
											padding: 5,
											marginBottom: 10,
											borderBottomWidth: 0.3,
											borderBottomColor: "#C5C5C5",
											paddingBottom: 10,
											backgroundColor:
												d.answer === ""
													? "#FFF"
													: "transparent",
										}}>
										<TouchableOpacity
											onPress={async (e) => {
												this.scrollToThisPosition(i);
												// setTimeout(() => {
												this.setState({
													selectedQuestion: i,
													showQuestion: true,
												});
												// }, 300);
											}}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
													padding: 10,
												}}>
												{d.answer === "" ? (
													<Image
														source={open}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																"contain",
															opacity: 0.3,
														}}
													/>
												) : d.answer ===
												  d.requiredAnswer ? (
													<Image
														source={tick}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																"contain",
														}}
													/>
												) : (
													<Image
														source={flagged}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																"contain",
														}}
													/>
												)}
												<Text
													style={{
														flex: 1,
														color: "#2A4E95",
														fontSize: 19,
														marginLeft: 14,
													}}>
													{d.title}
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								);
							})}

							<View
								style={{
									borderTopWidth: 0.3,
									borderColor: "#C5C5C5",
								}}
							/>

							<View
								style={{
									padding: 5,
									marginTop: 50,
								}}>
								{/* <Text
									style={{
										color: "#2A4E95",
										fontSize: 12,
										marginLeft: 14,
										marginTop: 15,
									}}>
									Inspection Invoice
								</Text> */}

								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "#2A4E95",
											fontSize: 14,
											fontWeight: "500",
											flex: 1,
										}}>
										Invoice number
									</Text>
									<Text
										style={{
											color: "#2A4E95",
											fontSize: 14,
											fontWeight: "500",
											flex: 1.3,
										}}>
										Invoice PDF
									</Text>
								</View>

								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										paddingBottom: 10,
										marginBottom: 10,
										marginTop: 10,
									}}>
									<TextInput
										// ref={(ref) => {
										// 	this.textinput = ref;
										// }}
										style={{
											width: "100%",
											padding: 5,
											fontSize: 20,
											borderRadius: 6,
											color: "#2A4E95",
											fontWeight: "900",
											fontFamily: "Avenir",
											textAlign: "center",
											borderWidth: 0.5,
											borderColor: "#2A4E95",
											backgroundColor: "#FFF",
											marginRight: 4,
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='characters'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										// placeholder={`work order number`}
										value={this.state.workOrder}
										onChangeText={(text) => {
											let ddd = text
												.replace(/\s/g, "")
												.toUpperCase();
											this.setState({
												invoiceNumber: ddd,
											});
										}}
									/>
									<PDFUploader
										multiple={false}
										isFile={this.state.newPDFData}
										base64String={(base64String, type) =>
											this.setState({
												newPDFData: base64String,
												fileType: type,
											})
										}
									/>
								</View>

								<Text
									style={{
										color: "#2A4E95",
										fontSize: 14,
										fontWeight: "500",
										flex: 1,
									}}>
									Amount $
								</Text>
								<View
									style={{
										flexDirection: "row",
										width: "100%",
										alignItems: "center",
										paddingBottom: 10,
										marginBottom: 10,
										marginTop: 10,
									}}>
									<TextInput
										// ref={(ref) => {
										// 	this.textinput = ref;
										// }}
										style={{
											width: "100%",
											padding: 5,
											fontSize: 20,
											borderRadius: 6,
											color: "#2A4E95",
											fontWeight: "900",
											fontFamily: "Avenir",
											textAlign: "center",
											borderWidth: 0.5,
											borderColor: "#2A4E95",
											backgroundColor: "#FFF",
											marginRight: 4,
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='characters'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										// placeholder={`work order number`}
										value={this.state.invoiceAmount}
										onChangeText={(text) => {
											this.setState({
												invoiceAmount: text,
											});
										}}
									/>
								</View>
							</View>

							{this.state.newPDFData !== "" &&
							this.state.invoiceNumber !== "" ? (
								<View
									style={{
										marginTop: 30,
										width: "100%",
										padding: 5,
									}}>
									<TouchableOpacity
										onPress={() => {
											this.props.saveData(
												this.props.report.questions,
											);
										}}>
										<View
											style={{
												flex: 1,
												width: "100%",
												borderRadius: 10,
												alignItems: "center",
												justifyContent: "center",
												padding: 12,
												backgroundColor: "#3582F9",
											}}>
											<Text
												style={{
													color: "#FFF",
													fontSize: 18,
												}}>
												Submit report
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							) : (
								<View />
							)}

							<View
								style={{
									height: this.props.screenHeight * 0.3,
								}}
							/>
						</ScrollView>
						{/* <View
							pointerEvents='none'
							style={{
								position: "absolute",
								bottom: -1,
								// opacity: 0.4,
							}}>
							<Image
								source={bottomImage}
								style={{
									height:
										this.props.screenWidth * (117 / 423),
									resizeMode: "contain",

									width: this.props.screenWidth,
									maxWidth: 400,
									maxHeight: 400 * (117 / 423),
									marginLeft: "auto",
									marginRight: "auto",
								}}
							/>
						</View> */}
					</View>

					{this.state.showDeleteModal ? (
						this.basicModal()
					) : (
						<View
							style={{
								height: 0,
								padding: 0,
								margin: 0,
								display: "none",
							}}
						/>
					)}
					{this.state.showQuestion ? (
						<Modal
							animationType='none'
							transparent={true}
							visible={true}>
							<View
								style={{
									overflow: "-moz-hidden-unscrollable",
									flex: 1,
									height: this.props.screenHeight,
									width: this.props.screenWidth,
								}}>
								<View
									style={{
										width: this.props.screenWidth,

										flexDirection: "row",
										alignItems: "center",
										backgroundColor: "#FFF",
										padding: 10,
										borderBottomWidth: 0.3,
										borderBottomColor: "#C5C5C5",
										justifyContent: "space-between",
										height: 50,
										// paddingTop: 15,
									}}>
									<TouchableOpacity
										style={{
											flexDirection: "row",
											alignItems: "center",
										}}
										onPress={() =>
											this.setState({
												showQuestion: false,
											})
										}>
										<Image
											source={backSvg}
											style={{
												width: 40,
												height: 19,
												resizeMode: "contain",
											}}
										/>
										<Text
											style={{
												color: "#2A4E95",
												fontSize: 18,
											}}>
											Back
										</Text>
									</TouchableOpacity>

									<Image
										source={logo}
										style={{
											width: 100,
											height: 25,
											resizeMode: "contain",
										}}
									/>
								</View>
								<View
									style={{
										// height: this.props.screenHeight - 50,
										width: this.props.screenWidth,
										flex: 1,
										backgroundColor: APP_COLOURS.OFFWHITE,
										maxWidth: 400,
										marginLeft: "auto",
										marginRight: "auto",
									}}>
									<ScrollView
										showsVerticalScrollIndicator={false}>
										<QuestionField
											selectedQuestion={
												this.state.selectedQuestion
											}
											data={this.props.report.questions}
											_back={() =>
												this.setState({
													showQuestion: false,
												})
											}
										/>
										<View
											style={{
												height: 50,
											}}
										/>
									</ScrollView>
								</View>
							</View>
						</Modal>
					) : (
						<View
							style={{
								height: 0,
								padding: 0,
								margin: 0,
								display: "none",
							}}
						/>
					)}
				</div>
			);
	}

	basicModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: this.props.screenWidth,
							maxWidth: 500,
							// height: this.props.screenHeight,

							maxHeight: 500,
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										addNewTaskModal: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "100%",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 15,
									fontFamily: "Avenir Next",
									margin: 5,
									paddingLeft: 10,
									fontWeight: "700",
								}}>
								Are you sure you want to delete{" "}
								{this.state.folderTopDelete}?
							</Text>
							{/* <View
								style={{
									// width: '100%',
									height: 60,
									borderRadius: 5,
									borderWidth: 0.3,
									borderColor: "#C5C5C5",
								}}></View> */}

							<TouchableOpacity
								onPress={() =>
									this.removeFolder(
										this.state.folderTopDelete,
									)
								}>
								<View
									style={{
										width: "100%",
										backgroundColor: APP_COLOURS.BLUE,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
										marginBottom: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 11,
										}}>
										Yes
									</Text>
								</View>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										showDeleteModal: false,
									})
								}>
								<View
									style={{
										width: "100%",
										// backgroundColor: APP_COLOURS.BLUE,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.BLUE,
											fontSize: 11,
										}}>
										Cancel
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}

export default index;
