import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Image,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../APP_VARS";
import add from "./../../assets/images/add_btn.png";
import open from "./../../assets/images/open.png";
import tick from "./../../assets/images/tick.svg";
import flagged from "./../../assets/images/flagged.svg";
import delete_ from "./../../assets/images/delete.png";
import bin from "./../../assets/images/bin.png";
// import ImageUploader from "./ImageUploader";
import ClickandDragUploader from "./ClickandDragUploader/index";
import { api_book_line_uploader } from "../Api";

export default class QuestionField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedAnswer: "",
			yesImageBase64: "",
			layoutOfNoImageBox: "",
			layoutOfNoImageBox_: "",
			issueImages: [
				{
					id: 1,
					beforeImage: "",
					afterImage: "",
					description: "",
				},
			],

			showImage: false,
			saved: true,
		};
		this._timeout = 1000;
		this.textValue = "";
		this.indexValue = "";
	}

	componentDidMount() {
		console.log(this.props.data[this.props.selectedQuestion]);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			console.log("componentDidUpdate");
		}
	}

	snoozeUpdateTimer = async () => {
		await this.setState({
			saved: false,
		});
		await clearTimeout(this._timeout);
		this._timeout = await setTimeout(() => {
			this.updateDescription();
		}, 1000);
	};

	updateDescription = async () => {
		console.log(this.textValue);
		let d = this.props.data[this.props.selectedQuestion].data;
		d[this.indexValue].description = this.textValue;
		// console.log(d);
		await this.setPropsData("data", d);
		await this.setState({
			saved: true,
		});
	};

	CorrectField = () => {
		return (
			<View
				style={{
					flex: 1,
				}}>
				<View
					style={{
						flex: 1,
						padding: 10,
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					<Text
						style={{
							color: "#2A4E95",
							fontSize: 16,
							marginTop: 20,
							marginBottom: 5,
						}}>
						Please add atleast one image
					</Text>
					{this.props.data[this.props.selectedQuestion].data.map(
						(line, i) => (
							<View
								style={{
									flex: 1,
									marginTop: 20,
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<View style={{}}>
										<TouchableOpacity
											style={{
												width: 40,
												height: 20,
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={async () => {
												let d_ =
													this.props.data[
														this.props
															.selectedQuestion
													];

												let filtered_ =
													await d_.data.filter(
														(item, index) =>
															item.id !== line.id,
													);

												await this.setPropsData(
													"data",
													filtered_,
												);
											}}>
											<Image
												source={bin}
												style={{
													width: 22,
													height: 22,
													// borderRadius: 20,
													resizeMode: "contain",
													marginRight: 5,
												}}
											/>
										</TouchableOpacity>
									</View>
								</View>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<View
										onLayout={(e) => {
											if (
												this.state
													.layoutOfNoImageBox_ == ""
											) {
												this.setState({
													layoutOfNoImageBox_:
														e.nativeEvent.layout,
												});

												console.log(
													e.nativeEvent.layout,
												);
											}
										}}
										style={{
											flex: 1,
											borderRadius: 10,
											borderColor: "#2A4E95",
											borderWidth: 1,
											borderStyle: "dashed",
											// overflow: "hidden",
											marginTop: 10,
											backgroundColor: "#FFF",
										}}>
										{line.beforeImage !== "" ? (
											<>
												{this.state
													.layoutOfNoImageBox_ !==
													"" &&
												this.state.showImage ? (
													<Image
														source={{
															uri: `${line.beforeImage}`,
														}}
														style={{
															width: this.state
																.layoutOfNoImageBox_
																.width,
															height: this.state
																.layoutOfNoImageBox_
																.height,
															resizeMode: "cover",
															borderRadius: 10,
														}}
													/>
												) : (
													<View
														onLayout={(e) => {
															this.setState({
																showImage: true,
															});
															// THIS IS JUST USED TO GET A LAYOPUT
														}}>
														<ClickandDragUploader
															title={"Before"}
															base64String={async (
																base64String,
															) => {}}
														/>
													</View>
												)}
												<View
													style={{
														position: "absolute",
														right: 5,
														top: 5,
													}}>
													<TouchableOpacity
														style={{
															width: 40,
															height: 40,
															alignItems:
																"center",
															justifyContent:
																"center",
														}}
														onPress={async () => {
															let d =
																this.props.data[
																	this.props
																		.selectedQuestion
																].data;

															d[i].beforeImage =
																"";

															console.log(d);
															await this.setPropsData(
																"data",
																d,
															);
														}}>
														<Image
															source={delete_}
															style={{
																width: 22,
																height: 22,
																// borderRadius: 20,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
														/>
													</TouchableOpacity>
												</View>
											</>
										) : (
											<ClickandDragUploader
												title={"Before"}
												base64String={async (
													base64String,
												) => {
													let d =
														this.props.data[
															this.props
																.selectedQuestion
														].data;

													d[i].beforeImage =
														base64String;

													console.log(d);
													await this.setPropsData(
														"data",
														d,
													);
												}}
											/>
										)}
									</View>
								</View>
								{/* <TimeoutTextBox
									description={line.description}
								/> */}
								<View
									style={{
										// flexDirection: "row",
										width: "100%",
										alignItems: "center",
										borderBottomColor: "#797979",
										borderBottomWidth: 0.3,
										paddingBottom: 20,
										marginBottom: 20,
										marginTop: 10,
									}}>
									<View
										style={{
											// backgroundColor:
											// 	"rgba(52, 52, 52, 0.2)",
											borderRadius: 10,
											alignItems: "center",
											justifyContent: "center",
											position: "absolute",
											bottom: 5,
											right: 5,
										}}>
										{this.state.saved ? (
											<Image
												style={{
													width: 12,
													height: 12,
													resizeMode: "contain",
													display:
														line.description == ""
															? "none"
															: "flex",
												}}
												source={tick}
											/>
										) : (
											<View />
										)}
									</View>
									<TextInput
										ref={(ref) => (this.textBox = ref)}
										style={{
											width: "100%",
											padding: 10,
											fontSize: 16,
											borderRadius: 9,
											color: "#2A4E95",
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: this.state.saved
												? APP_COLOURS.BG2
												: "red",
											backgroundColor: "#FFF",
										}}
										placeholderTextColor={"#797979"}
										multiline
										numberOfLines={3}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`description`}
										defaultValue={line.description}
										onChangeText={async (text) => {
											this.textValue = text;
											this.indexValue = i;
											await this.setState({
												saved: false,
											});
											this.snoozeUpdateTimer();
										}}
									/>
								</View>
							</View>
						),
					)}
				</View>

				<View
					style={{
						marginTop: 10,
					}}>
					<TouchableOpacity
						onPress={async () => {
							// await this.setState({
							// 	issueImages: concat_,
							// });

							let d =
								this.props.data[this.props.selectedQuestion];
							let obj = {
								id: d.data.length + 1,
								beforeImage: "",
								afterImage: "",
								description: "",
							};
							let concat_ = await d.data.concat(obj);

							await this.setPropsData("data", concat_);
						}}>
						<View
							style={{
								borderWidth: 1,
								borderColor: "#2A4E95",
								flex: 1,
								borderRadius: 10,
								alignItems: "center",
								justifyContent: "center",
								padding: 8,
								backgroundColor: "#2A4E95",
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 15,
								}}>
								+ Add{" "}
								{this.props.data[this.props.selectedQuestion]
									.data.length > 0
									? "another "
									: ""}
								image
							</Text>
						</View>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	IssueField = () => {
		return (
			<View
				style={{
					flex: 1,
					borderTopWidth: 0.3,
					borderColor: APP_COLOURS.BG2,
					marginTop: 20,
				}}>
				<Text
					style={{
						color: "#2A4E95",
						fontSize: 16,
						marginTop: 20,
						marginBottom: 5,
					}}>
					Please describe in as much detail as possible and include a
					before and after picture.
				</Text>

				<View
					style={{
						flex: 1,
					}}>
					{this.props.data[this.props.selectedQuestion].data.map(
						(line, i) => (
							<View
								style={{
									flex: 1,
									marginTop: 20,
								}}>
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<Text>Line {i + 1}</Text>
									<View
										style={
											{
												// position: "absolute",
												// right: 5,
												// top: 5,
											}
										}>
										<TouchableOpacity
											style={{
												width: 40,
												height: 20,
												alignItems: "center",
												justifyContent: "center",
											}}
											onPress={async () => {
												let d_ =
													this.props.data[
														this.props
															.selectedQuestion
													];

												let filtered_ =
													await d_.data.filter(
														(item, index) =>
															item.id !== line.id,
													);

												await this.setPropsData(
													"data",
													filtered_,
												);
											}}>
											<Image
												source={bin}
												style={{
													width: 22,
													height: 22,
													// borderRadius: 20,
													resizeMode: "contain",
													marginRight: 5,
												}}
											/>
										</TouchableOpacity>
									</View>
								</View>
								<View
									style={{
										flex: 1,
										flexDirection: "row",
										justifyContent: "space-between",
									}}>
									<View
										onLayout={(e) => {
											if (
												this.state
													.layoutOfNoImageBox_ == ""
											) {
												this.setState({
													layoutOfNoImageBox_:
														e.nativeEvent.layout,
												});

												console.log(
													e.nativeEvent.layout,
												);
											}
										}}
										style={{
											flex: 1,
											borderRadius: 10,
											borderColor: "#2A4E95",
											borderWidth: 1,
											borderStyle: "dashed",
											// overflow: "hidden",
											marginTop: 10,
											backgroundColor: "#FFF",
										}}>
										{line.beforeImage !== "" ? (
											<>
												{this.state
													.layoutOfNoImageBox_ !==
													"" &&
												this.state.showImage ? (
													<Image
														source={{
															uri: `${line.beforeImage}`,
														}}
														style={{
															width: this.state
																.layoutOfNoImageBox_
																.width,
															height: this.state
																.layoutOfNoImageBox_
																.height,
															resizeMode: "cover",
															borderRadius: 10,
														}}
													/>
												) : (
													<View
														onLayout={(e) => {
															this.setState({
																showImage: true,
															});
															// THIS IS JUST USED TO GET A LAYOPUT
														}}>
														<ClickandDragUploader
															title={"Before"}
															base64String={async (
																base64String,
															) => {}}
														/>
													</View>
												)}
												<View
													style={{
														position: "absolute",
														right: 5,
														top: 5,
													}}>
													<TouchableOpacity
														style={{
															width: 40,
															height: 40,
															alignItems:
																"center",
															justifyContent:
																"center",
														}}
														onPress={async () => {
															let d =
																this.props.data[
																	this.props
																		.selectedQuestion
																].data;

															d[i].beforeImage =
																"";

															console.log(d);
															await this.setPropsData(
																"data",
																d,
															);
														}}>
														<Image
															source={delete_}
															style={{
																width: 22,
																height: 22,
																// borderRadius: 20,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
														/>
													</TouchableOpacity>
												</View>
											</>
										) : (
											<ClickandDragUploader
												title={"Before"}
												base64String={async (
													base64String,
												) => {
													let d =
														this.props.data[
															this.props
																.selectedQuestion
														].data;

													d[i].beforeImage =
														base64String;

													console.log(d);
													await this.setPropsData(
														"data",
														d,
													);
												}}
											/>
										)}
									</View>
									<View
										style={{
											width: 13,
										}}
									/>
									<View
										style={{
											flex: 1,
											borderRadius: 10,
											borderColor: "#2A4E95",
											borderWidth: 1,
											borderStyle: "dashed",
											// overflow: "hidden",
											marginTop: 10,
											backgroundColor: "#FFF",
										}}>
										{line.afterImage !== "" ? (
											<>
												{this.state
													.layoutOfNoImageBox_ !==
													"" &&
												this.state.showImage ? (
													<Image
														source={{
															uri: `${line.afterImage}`,
														}}
														style={{
															width: this.state
																.layoutOfNoImageBox_
																.width,
															height: this.state
																.layoutOfNoImageBox_
																.height,
															resizeMode: "cover",
															borderRadius: 10,
														}}
													/>
												) : (
													<View
														onLayout={(e) => {
															this.setState({
																showImage: true,
															});
															// THIS IS JUST USED TO GET A LAYOPUT
														}}>
														<ClickandDragUploader
															title={"After"}
															base64String={async (
																base64String,
															) => {}}
														/>
													</View>
												)}
												<View
													style={{
														position: "absolute",
														right: 5,
														top: 5,
													}}>
													<TouchableOpacity
														style={{
															width: 40,
															height: 40,
															alignItems:
																"center",
															justifyContent:
																"center",
														}}
														onPress={async () => {
															let d =
																this.props.data[
																	this.props
																		.selectedQuestion
																].data;

															d[i].afterImage =
																"";

															console.log(d);
															await this.setPropsData(
																"data",
																d,
															);
														}}>
														<Image
															source={delete_}
															style={{
																width: 22,
																height: 22,
																// borderRadius: 20,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
														/>
													</TouchableOpacity>
												</View>
											</>
										) : (
											<ClickandDragUploader
												title={"After"}
												base64String={async (
													base64String,
												) => {
													let d =
														this.props.data[
															this.props
																.selectedQuestion
														].data;

													d[i].afterImage =
														base64String;

													console.log(d);
													await this.setPropsData(
														"data",
														d,
													);
												}}
											/>
										)}
									</View>
								</View>
								<View
									style={{
										// flexDirection: "row",
										width: "100%",
										alignItems: "center",
										borderBottomColor: "#797979",
										borderBottomWidth: 0.3,
										paddingBottom: 20,
										marginBottom: 20,
										marginTop: 10,
									}}>
									<View
										style={{
											// backgroundColor:
											// 	"rgba(52, 52, 52, 0.2)",
											borderRadius: 10,
											alignItems: "center",
											justifyContent: "center",
											position: "absolute",
											bottom: 5,
											right: 5,
										}}>
										{this.state.saved ? (
											<Image
												style={{
													width: 12,
													height: 12,
													resizeMode: "contain",
													display:
														line.description == ""
															? "none"
															: "flex",
												}}
												source={tick}
											/>
										) : (
											<View />
										)}
									</View>
									<TextInput
										ref={(ref) => (this.textBox = ref)}
										style={{
											width: "100%",
											padding: 10,
											fontSize: 16,
											borderRadius: 9,
											color: "#2A4E95",
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: this.state.saved
												? APP_COLOURS.BG2
												: "red",
											backgroundColor: "#FFF",
										}}
										placeholderTextColor={"#797979"}
										multiline
										numberOfLines={3}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`description`}
										defaultValue={line.description}
										onChangeText={async (text) => {
											this.textValue = text;
											this.indexValue = i;
											await this.setState({
												saved: false,
											});
											this.snoozeUpdateTimer();
										}}
									/>
								</View>
							</View>
						),
					)}

					<View
						style={{
							marginTop: 10,
						}}>
						<TouchableOpacity
							onPress={async () => {
								let d =
									this.props.data[
										this.props.selectedQuestion
									];
								let obj = {
									id: d.data.length + 1,
									beforeImage: "",
									afterImage: "",
									description: "",
								};
								let concat_ = await d.data.concat(obj);

								await this.setPropsData("data", concat_);
							}}>
							<View
								style={{
									borderWidth: 1,
									borderColor: "#2A4E95",
									flex: 1,
									borderRadius: 10,
									alignItems: "center",
									justifyContent: "center",
									padding: 8,
									backgroundColor: "#2A4E95",
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 15,
									}}>
									+ Add{" "}
									{this.props.data[
										this.props.selectedQuestion
									].data.length > 0
										? "another "
										: ""}
									image
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	};

	setPropsData = (key, value) => {
		let d = this.props.data[this.props.selectedQuestion];
		d[key] = value;

		this.setState({}); // this re rednders the comp

		console.log("setPropsData questionFiled", d);
		// this.props.data;
	};

	render() {
		let d = this.props.data[this.props.selectedQuestion];
		return (
			<View
				style={{
					flex: 1,
					padding: 5,
					backgroundColor: APP_COLOURS.OFFWHITE,
				}}>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						padding: 10,
						marginBottom: 30,
					}}>
					{this.state.selectedAnswer === "" && d.answer === "" ? (
						<Image
							source={open}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
							}}
						/>
					) : this.state.selectedAnswer === d.requiredAnswer ||
					  d.answer === d.requiredAnswer ? (
						<Image
							source={tick}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
							}}
						/>
					) : (
						<Image
							source={flagged}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
							}}
						/>
					)}
					<Text
						style={{
							color: "#2A4E95",
							fontSize: 19,
							marginLeft: 14,
						}}>
						{d.title}
					</Text>
				</View>

				<View
					style={{
						flex: 1,
						flexDirection: "row",
						// paddingLeft: 20,
					}}>
					<View
						style={{
							flex: 1,
							padding: 5,
						}}>
						<TouchableOpacity
							onPress={() => {
								this.setState({
									selectedAnswer: "yes",
								});
								this.setPropsData("answer", "yes");
							}}>
							<View
								style={{
									borderWidth: 1,
									borderColor: "#2A4E95",
									flex: 1,
									borderRadius: 10,
									alignItems: "center",
									justifyContent: "center",
									padding: 8,

									backgroundColor:
										this.state.selectedAnswer === "yes" ||
										d.answer === "yes"
											? "#2A4E95"
											: "#FFF",
								}}>
								<Text
									style={{
										color:
											this.state.selectedAnswer ===
												"yes" || d.answer === "yes"
												? "#FFF"
												: "#2A4E95",
										fontSize: 19,
									}}>
									Yes
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							padding: 5,
						}}>
						<TouchableOpacity
							onPress={() => {
								this.setState({
									selectedAnswer: "no",
								});
								this.setPropsData("answer", "no");
							}}>
							<View
								style={{
									borderWidth: 1,
									borderColor: "#2A4E95",
									flex: 1,
									borderRadius: 10,
									alignItems: "center",
									justifyContent: "center",
									padding: 8,

									backgroundColor:
										this.state.selectedAnswer === "no" ||
										d.answer === "no"
											? "#2A4E95"
											: "#FFF",
								}}>
								<Text
									style={{
										color:
											this.state.selectedAnswer ===
												"no" || d.answer === "no"
												? "#FFF"
												: "#2A4E95",
										fontSize: 19,
									}}>
									No
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View>

				<View
					style={
						{
							// flex: 1,
							// justifyContent: "center",
							// alignItems: "center",
							// paddingLeft: 20,
						}
					}>
					{d.answer === d.requiredAnswer ? (
						this.CorrectField(d)
					) : (
						<View />
					)}
					{d.answer !== d.requiredAnswer && d.answer !== "" ? (
						this.IssueField(d)
					) : (
						<View />
					)}
				</View>

				<View
					style={{
						marginTop: 30,
						marginBottom: 30,
					}}>
					<TouchableOpacity
						onPress={async () => {
							this.props._back();
						}}>
						<View
							style={{
								borderWidth: 1,
								borderColor: "#3582F9",
								flex: 1,
								borderRadius: 10,
								alignItems: "center",
								justifyContent: "center",
								padding: 8,
								backgroundColor: "#3582F9",
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 15,
								}}>
								{this.state.saved ? "Done" : "Saving..."}
							</Text>
						</View>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}
