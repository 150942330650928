import React, { Component } from "react";
import {
	View,
	Text,
	Dimensions,
	Image,
	TouchableOpacity,
	Modal,
} from "react-native";

import Home from "./Home/Home";
import WorkOrder from "./Unauthed/WorkOrder";
import Login from "./Unauthed/Login";
import logo from "./../assets/images/camelot.png";
import female1 from "../assets/images/female1.png";
import male1 from "../assets/images/male1.png";
import male2 from "../assets/images/male2.png";
import tick from "./../assets/images/tick.svg";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			submitSuccess: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			jwt: "",
			permission: "",
			user_data: {
				id: 1,
				first_name: "Wesley",
				last_name: "Cheia",
				// avatar: male1,
			},
			workOrder: "",
			location: "",
			asset_number: "",
			address: "",
			date: "",
			questions: [],
		};
	}

	logOut = async () => {
		await sessionStorage.clear();
		this.setState({
			jwt: "",
			permission: "",
			user_data: {
				id: 1,
				first_name: "Wesley",
				last_name: "Cheia",
				avatar: male1,
			},
		});
	};

	componentDidMount() {
		// this.validateSession();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		// this.saveJsonDataServer();
	}

	saveJsonDataServer = () => {
		// this.setState({
		// 	loading: true,
		// });
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			command: "save",
			fileName: "69BOUNDARY",
			json_data: {
				assetNumber: "69BOUNDARY",
				address: "14 Dennis Road, Lonehill, Sandton",
				reports: [
					{
						id: 1,
						title: "Monthly PCR",
						description: "Property condition report",
						instructionPoints: [
							"Overall site condition: (Landscaping, Parkinglots, etc)",
							"Overall exterior condition: (Doors, Windows, walls, etc)",
							"Overall interior condition: (Doors, Windows, walls, etc)",
							"Any damage, graffiti or hazards",
							"All views (Front, back, sides, interior)",
						],
						questions: [
							{
								id: 1,
								title: "Is the gas utilities operating?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 2,
								title: "Incoming main water?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 3,
								title: "Is there electricity?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is the landscape maintained? Free from debris?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are there any hazards present?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is there a fence around the property,  is it in good condition? ",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is there a  dumpster enclosure?  Is it in good condition? Free from Debris?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are all doors intact and secure?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are all windows intact and secure?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are the exterior walls free of cracks?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is there graffiti or other damage?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is paint peeling or cracked?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Does the foundation have cracks?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are all exterior building lights operational?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are all all  parking lot/pole lights operational? (if applicable)",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Parking lot striping visible? Are there any pot holes?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are all meters present and secure? if no, which are missing?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Has the site been winterized? (when applicable)",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are the floors in good condition?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is there any evidence of mold/water intrusion? ",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "HVAC Notes - if system not on, please turn on and set temp accordingly to season",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is HVAC operational? Photograph T-STAT",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "T-Stat setting 75-80F (summer/spring)",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "T-Stat setting 55-65F (fall/winter)",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is P-trap clear with no odor?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Is Basement in good conditiion?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "is Mechanical room in good condition?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
						],
					},
					{
						id: 1,
						title: "Winterization",
						description: "Property winterization",
						instructionPoints: [
							"Overall site condition: (Landscaping, Parkinglots, etc)",
							"Overall exterior condition: (Doors, Windows, walls, etc)",
							"Overall interior condition: (Doors, Windows, walls, etc)",
							"Any damage, graffiti or hazards",
							"All views (Front, back, sides, interior)",
						],
						questions: [
							{
								id: 1,
								title: "Is electricity on?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 1,
								title: "Is Gas on?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 3,
								title: "Is heating system functioning",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Thermostat set at  60-65 degrees  and to Heat",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Gas valves shut off to kitchen equipment",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Water shut off at meter or main  water `shut off` (see fire sprinkler secion below) ",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "All internal and external water supply lines drained?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Water-heater and or boiler drained?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "All faucets, including exterior faucets, left open",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Non-toxic antifreeze placed in all taps, including, Kitchen, bar  ...... add checklist component ?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Wrapped fire & water main lines with insulation ",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Does property have irrigation system?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Does building have a 'wet' fire prinkler system?",
								answer: "",
								requiredAnswer: "no",
								requiredField: true,
								data: [],
							},
							{
								id: 4,
								title: "Are there any plumbing damages?",
								answer: "",
								requiredAnswer: "yes",
								requiredField: true,
								data: [],
							},
						],
					},
				],
			},
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/camelotapi/get_propertyData.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							// this.setState({
							// 	tasks: result,
							// 	loading: false,
							// });
						})
						.catch((error) => this.setState({}));
				} else {
					// this.setState({
					// 	tasks: [],
					// });
				}
			})
			.catch((error) => console.log("error", error));
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	resize() {
		// Resize logic
		// if the screen orientation changes, then change the resize,
		// but if just the height channges, leave it as old size.
		// Prevents iOS browser bar at the bottom from interfering
		if (window.innerWidth !== this.state.screenWidth) {
			// this.setState({
			// 	screenHeight: window.innerHeight,
			// 	screenWidth: window.innerWidth,
			// });
		}
	}

	render() {
		return (
			<div
				style={{
					overflow: "hidden",
					overscrollBehavior: "contain",
					overscrollBehaviorY: "contain",
				}}>
				<View
					style={{
						flex: 1,
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}>
					{this.state.workOrder === "" ? (
						<WorkOrder
							{...this.state}
							beginReport={(d) => this.beginReport(d)}
						/>
					) : (
						<View
							style={{
								height: 0,
								padding: 0,
								margin: 0,
								display: "none",
							}}
						/>
					)}

					{/* {this.state.permission == "" && this.state.jwt == "" ? (
						<Login jwt={(d) => this.setState(d)} />
					) : null} */}

					{this.state.workOrder !== "" ? (
						<Home
							{...this.state}
							user_data={this.state.user_data}
							saveData={async (d) => {
								await this.setState({
									questions: d,
								});
								await this.submitReportToServer();
							}}
							logOut={this.logOut.bind(this)}
							screenHeight={this.state.screenHeight}
							screenWidth={this.state.screenWidth}
						/>
					) : (
						<View
							style={{
								height: 0,
								padding: 0,
								margin: 0,
								display: "none",
							}}
						/>
					)}

					{this.state.loading ? (
						this.savingDataModal()
					) : (
						<View
							style={{
								height: 0,
								padding: 0,
								margin: 0,
								display: "none",
							}}
						/>
					)}

					{this.state.submitSuccess ? (
						this.submitSuccessModal()
					) : (
						<View
							style={{
								height: 0,
								padding: 0,
								margin: 0,
								display: "none",
							}}
						/>
					)}
				</View>
			</div>
		);
	}

	savingDataModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: this.state.screenWidth,
							maxWidth: 200,
							maxHeight: 100,
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "100%",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 15,
									fontFamily: "Avenir Next",
									margin: 5,
									paddingLeft: 10,
									fontWeight: "700",
								}}>
								Loading report...
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	submitSuccessModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						padding: 5,
					}}>
					<View
						style={{
							flex: 1,
							maxWidth: 400,
							maxHeight: 400,
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "space-between",
							alignItems: "center",
							padding: 15,
						}}>
						<Image
							source={logo}
							style={{
								width: 200,
								height: 50,
								resizeMode: "contain",
								marginBottom: 20,
							}}
						/>
						<Image
							source={tick}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
								marginBottom: 10,
							}}
						/>
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 20,
								fontFamily: "Avenir Next",
								fontWeight: "700",
							}}>
							Thank you
						</Text>
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 16,
								fontFamily: "Avenir Next",
								fontWeight: "400",
								marginBottom: 25,
							}}>
							Your report has been submitted successfully
						</Text>
						<View
							style={{
								width: "100%",
							}}>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										loading: false,
										submitSuccess: false,
										jwt: "",
										permission: "",
										user_data: {
											id: 1,
											first_name: "Wesley",
											last_name: "Cheia",
											// avatar: male1,
										},
										workOrder: "",
										location: "",
										asset_number: "",
										address: "",
										date: "",
										questions: [],
									});
								}}>
								<View
									style={{
										flex: 1,
										width: "100%",
										borderRadius: 10,
										alignItems: "center",
										justifyContent: "center",
										padding: 8,
										backgroundColor: "#3582F9",
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 16,
										}}>
										Done
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let jwt = await sessionStorage.getItem("jwt");
		let refresh_token = await sessionStorage.getItem("refresh_token");
		let permission = await sessionStorage.getItem("permission");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			jwt,
			refresh_token,
			permission,
		});

		if (jwt !== null && jwt !== "") {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			this.setState({
				token: "",
			});
			sessionStorage.clear();
		}
	};

	beginReport = async (d) => {
		console.log(d);
		await this.setState({
			...d,
		});
		this.saveJsonDataToServer();
	};

	getJsonDataFromServer = () => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			command: "get",
			fileName: "test",
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/camelotapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								tasks: result,
								loading: false,
							});
						})
						.catch((error) => this.setState({}));
				} else {
					this.setState({
						tasks: [],
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	saveJsonDataToServer = () => {
		this.setState({
			loading: true,
		});

		// console.log(this.state);
		// return;
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			json_data: this.state,
			command: "save",
			fileName: `${this.state.assetNumber}_${this.state.workOrder}`,
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/camelotapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					this.setState({
						loading: false,
					});
					// response
					// 	.json()
					// 	.then((result) => {
					// 		console.log("result", result);

					// 		// this.setState({
					// 		// 	tasks: result,
					// 		// 	loading: false,
					// 		// });
					// 	})
					// 	.catch((error) => {
					// 		this.setState({
					// 			tasks: [],
					// 		});
					// 	});
				} else {
					alert("error submitting report");
				}
			})
			.catch((error) => console.log("error", error));
	};

	submitReportToServer = () => {
		this.setState({
			loading: true,
		});

		// console.log(this.state);
		// return;

		let data = this.state;

		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			json_data: "saved from App",
			command: "save",
			fileName: `${this.state.assetNumber}_${this.state.workOrder}`,
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/camelotapi/write_jsonlocal.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					this.setState({
						loading: false,
						submitSuccess: true,
					});
					// response
					// 	.json()
					// 	.then((result) => {
					// 		console.log("result", result);

					// 		// this.setState({
					// 		// 	tasks: result,
					// 		// 	loading: false,
					// 		// });
					// 	})
					// 	.catch((error) => {
					// 		this.setState({
					// 			tasks: [],
					// 		});
					// 	});
				} else {
					alert("error submitting report");
				}
			})
			.catch((error) => console.log("error", error));
	};
}

export default index;
