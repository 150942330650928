import React, { useState, useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import moment from "moment";
import $ from "jquery";
import "./css.css";

function DragandDrop(props) {
	useEffect(() => {
		function clearUpload() {
			$(".file-upload-input").replaceWith(
				$(".file-upload-input").clone(),
			);
			$(".file-upload-content").hide();
			$(".image-upload-wrap").show();
			$("#photo").val();
		}
		$(".image-upload-wrap").bind("dragover", function () {
			$(".image-upload-wrap").addClass("image-dropping");
		});
		$(".image-upload-wrap").bind("dragleave", function () {
			$(".image-upload-wrap").removeClass("image-dropping");
		});

		$(".activity").hide();
		$(".drag-text").show();
	});

	function readURL() {
		console.log("readURL");
		var input = document.getElementById("photo");
		// console.log(input);
		// return;

		var reader = new FileReader();

		reader.onload = function (e) {
			// $(".image-upload-wrap").hide();
			// $(".file-upload-image").attr("src", e.target.result);
			// $(".file-upload-content").show();
			// $(".image-title").html(input.files[0].name);
			// $("#test3").val(input.files[0].name);
			// $("#fileToUpload").attr('src', e.target.result);

			// console.log(e.target);
			// return;
			// let replace_ = "data:image/png;base64,";
			let myStr = e.target.result;
			// let cleanString = myStr.replace(replace_, "");
			// console.log(cleanString);
			console.log(myStr);
			// props.base64String(base64EncodeUrl(myStr));
			props.base64String(myStr);
			//
			//
			// THE BELOW CODE ESCAPES BASE64 STRINGS
			// console.log("base64EncodeUrl(str)", base64EncodeUrl(myStr));
			// console.log(
			// 	"base64DecodeUrl(str)",
			// 	base64DecodeUrl(base64EncodeUrl(myStr)),
			// );
		};

		reader.readAsDataURL(input.files[0]);

		return;
	}

	return (
		<div>
			<script
				className='jsbin'
				src='https://ajax.googleapis.com/ajax/libs/jquery/1/jquery.min.js'></script>
			<div className='file-upload'>
				<div className='image-upload-wrap'>
					<input
						className='file-upload-input'
						id='photo'
						type='file'
						onChange={readURL}
						accept='image/*'
						multiple={false}
					/>
					<div className='drag-text'>
						<h3>{props.title}</h3>
					</div>
				</div>

				<div className='file-upload-content'>
					<img
						className='file-upload-image'
						src='#'
						alt='your image'
					/>
					<div className='image-title-wrap'>
						<button
							type='button'
							onclick='clearUpload()'
							className='remove-image'>
							Remove{" "}
							<span className='image-title'>Uploaded Image</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DragandDrop;

// import React, { Component } from "react";
// import { View, Text } from "react-native";
// import $ from "jquery";
// import "./css.css";

// class index extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {};
// 	}

// 	readURL = async (input) => {
// 		console.log("readURL");
// 		var property = document.getElementById("photo").files[0];
// 		var image_name = property.name;

// 		// console.log("image_name", image_name);

// 		var image_extension = image_name.split(".").pop().toLowerCase();

// 		// if(jQuery.inArray(image_extension,['gif','jpg','jpeg','']) == -1){
// 		//   alert("Invalid image file");
// 		// }

// 		let url = this.props.url + "api_post.php";

// 		// console.log(url);
// 		// return;

// 		var form_data = new FormData();
// 		await form_data.append("file", property);
// 		form_data.append("folder", this.props.folder);

// 		// console.log(props.folder);
// 		// return;

// 		await $.ajax({
// 			url: url,
// 			method: "POST",
// 			data: form_data,
// 			contentType: false,
// 			cache: false,
// 			processData: false,
// 			beforeSend: function () {
// 				// $('#msg').html('Loading......');
// 				console.log("beforeSend");
// 			},
// 			success: function () {
// 				this.props.reloadPage();
// 			},
// 		});
// 		await this.props.reloadPage();
// 	};

// 	render() {
// 		return (
// 			<div>
// 				<script
// 					className='jsbin'
// 					src='https://ajax.googleapis.com/ajax/libs/jquery/1/jquery.min.js'></script>
// 				<div className='file-upload'>
// 					<div className='image-upload-wrap'>
// 						<input
// 							className='file-upload-input'
// 							id='photo'
// 							type='file'
// 							onChange={() => this.readURL()}
// 							accept='image/*'
// 							multiple={false}
// 						/>
// 						<div className='drag-text'>
// 							<h3>Drag and drop file</h3>
// 						</div>
// 					</div>
// 					<div className='file-upload-content'>
// 						<img
// 							className='file-upload-image'
// 							src='#'
// 							alt='your image'
// 						/>
// 						<div className='image-title-wrap'>
// 							<button
// 								type='button'
// 								onclick='clearUpload()'
// 								className='remove-image'>
// 								Remove{" "}
// 								<span className='image-title'>Uploaded Image</span>
// 							</button>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

// export default index;
