import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ScrollView,
} from "react-native";

import { APP_COLOURS } from "../APP_VARS";
import logo from "./../../assets/images/camelot.png";
import bottomImage from "./../../assets/images/bottomImage.svg";
import tick from "./../../assets/images/tick.svg";
import locationimg from "./../../assets/images/location.png";
import moment from "moment";
import Geolocate from "./GeoLocate";

import { geolocated } from "react-geolocated";

export default class Welcome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showWorkorder: true,
			showAssetNumber: false,
			showLocation: false,
			showAddress: false,
			showReports: false,
			showTermsandConditions: false,
			doesntExist: false,
			geoPermissions: "",
			geoText: "",

			date_: moment().format("MM/DD/YYYY HH:mm"),

			workOrder: "",
			assetNumber: "",
			location: "",
			address: "",
			report: "",

			allReports: [
				{
					id: 1,
					title: "Monthly PCR",
					description: "Property condition report",
				},
				{
					id: 1,
					title: "Winterization",
					description: "Property winterization",
				},
			],

			instructionPoints: [
				"Overall site condition: (Landscaping, Parkinglots, etc)",
				"Overall exterior condition: (Doors, Windows, walls, etc)",
				"Overall interior condition: (Doors, Windows, walls, etc)",
				"Any damage, graffiti or hazards",
				"All views (Front, back, sides, interior)",
			],
		};
	}

	componentDidMount() {
		// this.getGeoPermissions();
	}

	setPropData = () => {
		this.props.setPropData(this.state.workOrder);
	};

	getGeoPermissions = () => {
		if (navigator.geolocation) {
			let per = navigator.permissions
				.query({ name: "geolocation" })
				.then(function (result) {
					return result.state;
					// if (result.state === "granted") {
					// 	console.log(result.state);
					// 	//If granted then you can directly call your function here
					// } else if (result.state === "prompt") {
					// 	console.log(result.state);
					// } else if (result.state === "denied") {
					// 	//If denied then you have to show instructions to enable location
					// }
					// result.onchange = function () {
					// 	console.log(result.state);
					// 	this.setState({
					// 		geoPermissions: result.state,
					// 	});
					// };
				});
			this.setState({
				geoPermissions: per,
			});
			console.log(per);
		} else {
			alert("Sorry Not available!");
		}
	};

	getJsonDataFromServer = (assetNumber) => {
		this.setState({
			loading: true,
		});
		var raw = JSON.stringify({
			jwt: "sdfiuvhsdihjjhbgjuhjwdcgGHGIjuhjkbJHGHJBkjb",
			command: "get",
			fileName: assetNumber,
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			"https://tetrice.co.za/REST/camelotapi/get_propertyData.php",
			requestOptions,
		)
			.then((response) => {
				console.log(response);
				if (response.status == "200") {
					response
						.json()
						.then((result) => {
							console.log("result", result);

							this.setState({
								...result,
								loading: false,
								showAssetNumber: false,
								// showLocation: true,
								showReports: true,
							});
						})
						.catch((error) => this.setState({}));
				} else {
					this.setState({
						loading: false,
						showWorkorder: true,
						showAssetNumber: false,
						showLocation: false,
						showAddress: false,
						showReports: false,
						showTermsandConditions: false,
						doesntExist: true,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	render() {
		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					backgroundColor: "#FFF",
					// backgroundColor: "red",
					height: this.props.screenHeight,
					width: this.props.screenWidth,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						width: this.props.screenWidth,
						height: this.props.screenHeight,
						maxWidth: 400,
						marginLeft: "auto",
						marginRight: "auto",
						// justifyContent: "center",
						alignItems: "center",
					}}>
					<Image
						source={logo}
						style={{
							width: 190,
							height: 60,
							resizeMode: "contain",
							// marginBottom: 40,
						}}
					/>
					<View
						style={{
							flex: 1,
							width: "100%",
							height: this.props.screenHeight - 200,
							// borderTopWidth: 0.3,
							padding: 5,
						}}>
						<p id='demo'></p>
						<ScrollView>
							{this.state.showWorkorder ? (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											color: "#2A4E95",
											fontSize: 16,
											fontWeight: "700",
											marginBottom: 40,
											marginTop: 40,
										}}>
										VENDOR SUBMISSION FORM
									</Text>

									<Text
										style={{
											color: "#797979",
											fontSize: 14,
											fontWeight: "300",
											marginBottom: 40,
											textAlign: "center",
										}}>
										In order to open a new report, please
										provide the work order number you were
										given
									</Text>

									<Text
										style={{
											color: "#2A4E95",
											fontSize: 16,
											fontWeight: "700",
											marginBottom: 7,
										}}>
										Work order number
									</Text>

									<View
										style={{
											flexDirection: "row",
											width: "100%",
											alignItems: "center",
											// borderBottomColor: "#797979",
											// borderBottomWidth: 0.3,
											paddingBottom: 10,
											marginBottom: 10,
											marginTop: 10,
										}}>
										<TextInput
											ref={(ref) => {
												this.textinput = ref;
											}}
											style={{
												width: "100%",
												padding: 10,
												fontSize: 18,
												borderRadius: 9,
												color: "#2A4E95",
												fontWeight: "800",
												fontFamily: "Avenir",
												textAlign: "center",
												borderWidth: 1,
												borderColor: APP_COLOURS.BG2,
												backgroundColor: "#FFF",
											}}
											placeholderTextColor={"#797979"}
											// multiline
											// numberOfLines={3}
											autoCapitalize='characters'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											// placeholder={`work order number`}
											value={this.state.workOrder}
											onChangeText={(text) => {
												let ddd = text
													.replace(/\s/g, "")
													.toUpperCase();
												this.setState({
													workOrder: ddd,
												});
											}}
										/>
									</View>

									<View
										style={{
											marginTop: 20,
											width: "100%",
											display:
												this.state.workOrder.length > 5
													? "flex"
													: "none",
										}}>
										<TouchableOpacity
											onPress={() => {
												this.setState({
													showAssetNumber: true,
													showWorkorder: false,
												});
											}}>
											<View
												style={{
													borderWidth: 0.5,
													borderColor: "#2A4E95",
													flex: 1,
													width: "100%",
													borderRadius: 10,
													alignItems: "center",
													justifyContent: "center",
													padding: 8,
													backgroundColor: "#2A4E95",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 14,
													}}>
													Next
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							) : null}

							{this.state.showAssetNumber ? (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Work order number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.workOrder}
											</Text>
										</View>
									</View>

									<Text
										style={{
											color: "#797979",
											fontSize: 14,
											fontWeight: "300",
											marginBottom: 40,
											textAlign: "center",
										}}>
										Please provide the asset number
									</Text>

									<Text
										style={{
											color: "#2A4E95",
											fontSize: 16,
											fontWeight: "700",
											marginBottom: 7,
										}}>
										Asset number
									</Text>

									<View
										style={{
											flexDirection: "row",
											width: "100%",
											alignItems: "center",
											// borderBottomColor: "#797979",
											// borderBottomWidth: 0.3,
											paddingBottom: 10,
											marginBottom: 10,
											marginTop: 10,
										}}>
										<TextInput
											ref={(ref) => {
												this.textinput = ref;
											}}
											style={{
												width: "100%",
												padding: 10,
												fontSize: 18,
												borderRadius: 9,
												color: "#2A4E95",
												fontWeight: "800",
												fontFamily: "Avenir",
												textAlign: "center",
												borderWidth: 1,
												borderColor: APP_COLOURS.BG2,
												backgroundColor: "#FFF",
											}}
											placeholderTextColor={"#797979"}
											multiline
											// numberOfLines={3}
											autoCapitalize='characters'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											// placeholder={`work order number`}
											value={this.state.assetNumber}
											onChangeText={async (text) => {
												let ddd = text
													.replace(/\s/g, "")
													.toUpperCase();
												this.setState({
													assetNumber: ddd,
												});
											}}
										/>
									</View>

									<View
										style={{
											marginTop: 20,
											width: "100%",
											display:
												this.state.assetNumber.length >
												2
													? "flex"
													: "none",
										}}>
										<TouchableOpacity
											onPress={async () => {
												await this.getJsonDataFromServer(
													this.state.assetNumber,
												);
											}}>
											<View
												style={{
													borderWidth: 0.5,
													borderColor: "#2A4E95",
													flex: 1,
													width: "100%",
													borderRadius: 10,
													alignItems: "center",
													justifyContent: "center",
													padding: 8,
													backgroundColor: "#2A4E95",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 14,
													}}>
													Next
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							) : null}

							{this.state.showLocation ? (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Image
										source={locationimg}
										style={{
											width: this.props.screenWidth,
											maxWidth: 320,
											height: this.props.screenWidth,
											maxHeight: 320,
											resizeMode: "contain",
											// marginBottom: 40,
										}}
									/>

									<Text
										style={{
											color: "#2A4E95",
											fontSize: 16,
											fontWeight: "700",
											marginBottom: 7,
										}}>
										Location
									</Text>

									<Geolocate />

									<View
										style={{
											marginTop: 20,
											width: "100%",
											// display:
											// 	this.state.geoPermissions ===
											// 	"granted"
											// 		? "flex"
											// 		: "none",
										}}>
										<TouchableOpacity
											onPress={async () => {
												if (navigator.geolocation) {
													let pos =
														await navigator.geolocation.getCurrentPosition(
															(position) => {
																this.setState({
																	location: {
																		lon: position
																			.coords
																			.longitude,
																		lat: position
																			.coords
																			.latitude,
																		acc: position
																			.coords
																			.accuracy,
																	},
																	showAddress: true,
																	showLocation: false,
																});
															},
														);
												} else {
													alert(
														"Please enable geo-location",
													);
												}
											}}>
											<View
												style={{
													borderWidth: 0.5,
													borderColor: "#3582F9",
													flex: 1,
													width: "100%",
													borderRadius: 10,
													alignItems: "center",
													justifyContent: "center",
													padding: 12,
													backgroundColor: "#3582F9",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 14,
													}}>
													Next
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							) : null}

							{this.state.showAddress ? (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Work order number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.workOrder}
											</Text>
										</View>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Asset number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.assetNumber}
											</Text>
										</View>
									</View>

									{/* <View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Date:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.date_}
											</Text>
										</View>
									</View> */}

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Coordinates:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 12,
													fontWeight: "700",
												}}>
												{this.state.location.lat}
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 12,
													fontWeight: "700",
												}}>
												{this.state.location.lon}
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 12,
													fontWeight: "700",
												}}>
												{this.state.location.acc}
											</Text>
										</View>
									</View>

									<Text
										ref={(ref) => (this.nextRef3 = ref)}
										onLayout={() =>
											this.nextRef3.scrollIntoView({
												behavior: "smooth",
												block: "start",
											})
										}
										style={{
											color: "#797979",
											fontSize: 14,
											fontWeight: "300",
											marginBottom: 40,
											textAlign: "center",
										}}>
										Please provide the address
									</Text>

									<Text
										style={{
											color: "#2A4E95",
											fontSize: 16,
											fontWeight: "700",
											marginBottom: 7,
										}}>
										Address
									</Text>

									<Text
										style={{
											color: "#2A4E95",
											fontSize: 16,
											fontWeight: "700",
											marginBottom: 7,
										}}>
										{this.state.address}
									</Text>

									{/* <View
										style={{
											flexDirection: "row",
											width: "100%",
											alignItems: "center",
											// borderBottomColor: "#797979",
											// borderBottomWidth: 0.3,
											paddingBottom: 5,
											marginBottom: 5,
											marginTop: 5,
										}}>
										<TextInput
											ref={(ref) => {
												this.textinput = ref;
											}}
											style={{
												width: "100%",
												padding: 10,
												fontSize: 18,
												borderRadius: 9,
												color: "#2A4E95",
												fontWeight: "800",
												fontFamily: "Avenir",
												textAlign: "center",
												borderWidth: 1,
												borderColor: APP_COLOURS.BG2,
												backgroundColor: "#FFF",
											}}
											placeholderTextColor={"#797979"}
											multiline
											// numberOfLines={3}
											autoCapitalize='characters'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Line 1`}
											onChangeText={(text) => {
												// this.setState({
												// 	assetNumber: text,
												// });
											}}
										/>
									</View>
									<View
										style={{
											flexDirection: "row",
											width: "100%",
											alignItems: "center",
											// borderBottomColor: "#797979",
											// borderBottomWidth: 0.3,
											paddingBottom: 5,
											marginBottom: 5,
											marginTop: 5,
										}}>
										<TextInput
											ref={(ref) => {
												this.textinput = ref;
											}}
											style={{
												width: "100%",
												padding: 10,
												fontSize: 18,
												borderRadius: 9,
												color: "#2A4E95",
												fontWeight: "800",
												fontFamily: "Avenir",
												textAlign: "center",
												borderWidth: 1,
												borderColor: APP_COLOURS.BG2,
												backgroundColor: "#FFF",
											}}
											placeholderTextColor={"#797979"}
											multiline
											// numberOfLines={3}
											autoCapitalize='characters'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Line 2`}
											onChangeText={(text) => {
												// this.setState({
												// 	assetNumber: text,
												// });
											}}
										/>
									</View>
									<View
										style={{
											flexDirection: "row",
											width: "100%",
											alignItems: "center",
											// borderBottomColor: "#797979",
											// borderBottomWidth: 0.3,
											paddingBottom: 5,
											marginBottom: 5,
											marginTop: 5,
										}}>
										<TextInput
											ref={(ref) => {
												this.textinput = ref;
											}}
											style={{
												width: "100%",
												padding: 10,
												fontSize: 18,
												borderRadius: 9,
												color: "#2A4E95",
												fontWeight: "800",
												fontFamily: "Avenir",
												textAlign: "center",
												borderWidth: 1,
												borderColor: APP_COLOURS.BG2,
												backgroundColor: "#FFF",
											}}
											placeholderTextColor={"#797979"}
											multiline
											// numberOfLines={3}
											autoCapitalize='characters'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Line 3`}
											onChangeText={(text) => {
												// this.setState({
												// 	assetNumber: text,
												// });
											}}
										/>
									</View> */}

									<View
										style={{
											marginTop: 20,
											width: "100%",
											// display:
											// 	this.state.assetNumber.length >
											// 	2
											// 		? "flex"
											// 		: "none",
										}}>
										<TouchableOpacity
											onPress={() => {
												this.setState({
													showAddress: false,
													showReports: true,
												});
											}}>
											<View
												style={{
													borderWidth: 0.5,
													borderColor: "#2A4E95",
													flex: 1,
													width: "100%",
													borderRadius: 10,
													alignItems: "center",
													justifyContent: "center",
													padding: 8,
													backgroundColor: "#2A4E95",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 14,
													}}>
													Next
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							) : null}

							{this.state.showReports ? (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Work order number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.workOrder}
											</Text>
										</View>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Asset number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.assetNumber}
											</Text>
										</View>
									</View>

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Date:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.date_}
											</Text>
										</View>
									</View>

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Address:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.address}
											</Text>
										</View>
									</View>

									<Text
										ref={(ref) => (this.nextRef2 = ref)}
										onLayout={() =>
											this.nextRef2.scrollIntoView({
												behavior: "smooth",
												block: "start",
											})
										}
										style={{
											color: "#797979",
											fontSize: 14,
											fontWeight: "300",
											marginBottom: 40,
											textAlign: "center",
										}}>
										Select the report you wish to complete
									</Text>

									{this.state.reports.map((d, i) => (
										<TouchableOpacity
											style={{
												flex: 1,
												width: "100%",
											}}
											onPress={() => {
												this.setState({
													report: d,
												});
											}}>
											<View
												style={{
													flex: 1,
													width: "100%",
													borderWidth: 0.5,
													borderColor: "#2A4E95",
													backgroundColor:
														this.state.report
															.title == d.title
															? "#2A4E95"
															: "#FFF",
													borderRadius: 10,
													padding: 15,
													marginBottom: 10,
												}}>
												<Text
													style={{
														color:
															this.state.report
																.title ==
															d.title
																? "#FFF"
																: "#2A4E95",
														fontSize: 15,

														fontWeight: "700",
													}}>
													{d.title}
												</Text>
												<Text
													style={{
														color:
															this.state.report
																.title ==
															d.title
																? "#FFF"
																: "#2A4E95",
														fontSize: 15,
														fontWeight: "300",
													}}>
													{d.description}
												</Text>
											</View>
										</TouchableOpacity>
									))}

									{this.state.report !== "" ? (
										<View
											ref={(ref) => (this.nextRef = ref)}
											onLayout={() =>
												this.nextRef.scrollIntoView({
													behavior: "smooth",
													block: "start",
												})
											}
											style={{
												marginTop: 20,
												width: "100%",
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														showReports: false,
														showTermsandConditions: true,
													});
												}}>
												<View
													style={{
														borderWidth: 0.5,
														borderColor: "#2A4E95",
														flex: 1,
														width: "100%",
														borderRadius: 10,
														alignItems: "center",
														justifyContent:
															"center",
														padding: 8,
														backgroundColor:
															"#2A4E95",
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 14,
														}}>
														Next
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									) : null}

									<View
										style={{
											height: 110,
										}}
									/>
								</View>
							) : null}

							{this.state.showTermsandConditions ? (
								<View
									style={{
										width: "100%",
										justifyContent: "center",
										alignItems: "center",
									}}>
									{/* <View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Work order number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.workOrder}
											</Text>
										</View>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Asset number:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.assetNumber}
											</Text>
										</View>
									</View>

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Date:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.date_}
											</Text>
										</View>
									</View>

									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											padding: 10,
											width: "100%",
											marginBottom: 10,
											borderBottomColor: "#797979",
											borderBottomWidth: 0.3,
										}}>
										<Image
											source={tick}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
												position: "absolute",
											}}
										/>

										<View
											style={{
												flex: 1,
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 13,
													fontWeight: "400",
												}}>
												Address:
											</Text>
											<Text
												style={{
													color: "#2A4E95",
													fontSize: 16,
													fontWeight: "700",
												}}>
												{this.state.address}
											</Text>
										</View>
									</View> */}

									<View
										ref={(ref) => (this.nextRef = ref)}
										onLayout={() =>
											this.nextRef.scrollIntoView({
												behavior: "smooth",
												block: "start",
											})
										}
										style={{
											flex: 1,
											width: "100%",
											borderWidth: 0.5,
											borderColor: "#2A4E95",
											backgroundColor: "#2A4E95",
											borderRadius: 10,
											padding: 15,
											marginBottom: 10,
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 15,
												fontWeight: "700",
											}}>
											{this.state.report.title}
										</Text>
										<Text
											style={{
												color: "#FFF",
												fontSize: 15,
												fontWeight: "300",
											}}>
											{this.state.report.description}
										</Text>
									</View>

									<View
										style={{
											width: "100%",
											padding: 10,
										}}>
										<Text
											style={{
												color: "#2A4E95",
												fontSize: 13,
												fontWeight: "600",
												fontStyle: "italic",
											}}>
											Instructions:
										</Text>
										<Text
											style={{
												color: "#2A4E95",
												fontSize: 13,
												fontWeight: "400",
												marginTop: 4,
												marginBottom: 30,
											}}>
											20 - 25 Photos at minimum, including
											but not limited to:
										</Text>

										{this.state.instructionPoints.map(
											(d, i) => (
												<View
													style={{
														flexDirection: "row",
														alignItems:
															"flex-start",
														marginBottom: 3,
													}}>
													<View
														style={{
															width: 10,
															height: 10,
															borderRadius: 5,
															backgroundColor:
																"#2A4E95",
															marginTop: 4,
															marginRight: 6,
														}}
													/>
													<Text
														style={{
															color: "#2A4E95",
															fontSize: 13,
															fontWeight: "300",
														}}>
														{d}
													</Text>
												</View>
											),
										)}
									</View>

									<View
										style={{
											marginTop: 20,
											width: "100%",
										}}>
										<TouchableOpacity
											onPress={() => {
												let {
													date_,
													workOrder,
													assetNumber,
													location,
													address,
													report,
													questions,
												} = this.state;

												let obj = {
													date_,
													workOrder,
													assetNumber,
													location,
													address,
													report,
													questions,
												};
												// console.log(
												// 	JSON.stringify(obj),
												// );
												// return;
												this.props.beginReport(obj);
											}}>
											<View
												style={{
													flex: 1,
													width: "100%",
													borderRadius: 10,
													alignItems: "center",
													justifyContent: "center",
													padding: 8,
													backgroundColor: "#3582F9",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 14,
													}}>
													Accept & Begin
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							) : null}

							<View
								style={{
									height: 110,
								}}
							/>
						</ScrollView>
					</View>

					<View
						style={{
							position: "absolute",
							bottom: -9,
							width: this.props.screenWidth,
							maxWidth: 400,
							marginLeft: "auto",
							marginRight: "auto",
							zIndex: 99,
							display: this.state.showLocation ? "none" : "flex",
						}}>
						<Image
							source={bottomImage}
							style={{
								height: 117,
								width: this.props.screenWidth,
								maxWidth: 400,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>

				{this.state.doesntExist ? (
					this.doesntExistsModal()
				) : (
					<View
						style={{
							height: 0,
							padding: 0,
							margin: 0,
							display: "none",
						}}
					/>
				)}

				{this.state.loading ? (
					this.savingDataModal()
				) : (
					<View
						style={{
							height: 0,
							padding: 0,
							margin: 0,
							display: "none",
						}}
					/>
				)}
			</div>
		);
	}

	doesntExistsModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						padding: 5,
					}}>
					<View
						style={{
							// flex: 1,
							// maxWidth: 400,
							// maxHeight: 400,
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "space-between",
							alignItems: "center",
							padding: 15,
						}}>
						{/* <Image
							source={logo}
							style={{
								width: 200,
								height: 50,
								resizeMode: "contain",
								marginBottom: 20,
							}}
						/>
						<Image
							source={tick}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
								marginBottom: 10,
							}}
						/> */}
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 20,
								fontFamily: "Avenir Next",
								fontWeight: "700",
							}}>
							Error!
						</Text>
						<Text
							allowFontScaling={false}
							style={{
								fontSize: 16,
								fontFamily: "Avenir Next",
								fontWeight: "400",
								marginBottom: 25,
							}}>
							Invalid work order or asset number
						</Text>
						<View
							style={{
								width: "100%",
							}}>
							<TouchableOpacity
								onPress={() => {
									this.setState({
										loading: false,
										doesntExist: false,
										workOrder: "",
										assetNumber: "",
									});
								}}>
								<View
									style={{
										flex: 1,
										width: "100%",
										borderRadius: 10,
										alignItems: "center",
										justifyContent: "center",
										padding: 8,
										backgroundColor: "#3582F9",
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 16,
										}}>
										Cancel
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	savingDataModal = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: this.state.screenWidth,
							maxWidth: 150,
							maxHeight: 100,
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "100%",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 15,
									fontFamily: "Avenir Next",
									margin: 5,
									paddingLeft: 10,
									fontWeight: "700",
								}}>
								Loading...
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}
