export const APP_COLOURS = {
	PRIMARY: "#17151D",
	SECONDARY: "#748969",
	BACKGROUND: "#F8FAFF",
	BG2: "#191D24",
	TEXTCOLOR: "#141D31",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	BUTTON2: "#252B35",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#F4F6FA",
	WHITE: "#FFF",
	GREEN: "#07D55D",
	ORANGE: "#FFD76E",
	RED: "#E35B5B",
	BLUE: "#0676ED",
	WHITE_TRANSPARENT: "rgba(212, 212, 212, 0.2)",
	WHITE_TRANSPARENT2: "rgba(25, 29, 36, 0.4)",
	WHITE_TRANSPARENT3: "#323A47",
};
